
var $basedriverNewsletterForm,
    $body,
    $header,
    $search,
    $searchOpen,
    $searchClose,
    $submenuBtn,
    $submenu,
    $toggleFAQ,
    $toggleMenu;


$(document).ready(function($) {

    console.log('%cLinku Novio',"font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px");

    $basedriverNewsletterForm   = $('.js-basedriver-newsletter-form');
    $body                       = $('body');
    $header                     = $('.js-header');
    $search                     = $('.js-search');
    $searchOpen                 = $('.js-open-search');
    $searchClose                = $('.js-close-search');
    $submenuBtn                 = $('.js-submenu-button');
    $submenu                    = $('.js-submenu');
    $toggleFAQ                  = $('.js-toggle-faq');
    $toggleMenu                 = $('.js-toggle-menu');

    $(window).on('scroll', function () {
        var scrollingHeaderClass = 's-header--shadow';

        if ($(this).scrollTop() >= 75 && !$header.hasClass(scrollingHeaderClass)) {
            $header.addClass(scrollingHeaderClass);
        } else if($(this).scrollTop() < 75 && $header.hasClass(scrollingHeaderClass)) {
            $header.removeClass(scrollingHeaderClass);
        }
    });

    $toggleMenu.on('click', function (e) {
        e.preventDefault(); // Prevents scrolling to top

        if ($(this).hasClass('is-open')) {
            $(this).removeClass('is-open');
            $header.removeClass('s-header--mob-menu-is-open');
        } else {
            $(this).addClass('is-open');
            $header.addClass('s-header--mob-menu-is-open');
        }
    });

    $submenuBtn.on('click', function (e) {
        if (isMobile()) {
            e.preventDefault();

            var $activeMenuItem = $(this).closest('.js-menu-item');
            var $activeSubmenu = $activeMenuItem.find('.js-submenu');
            var activeClassName = 'is-open';
            var buttonOpenClassName = 'c-menu__submenu-button--mob-is-open';

            $submenu.stop().slideUp();

            if ($activeSubmenu.hasClass(activeClassName)) { // If clicked submenu is active
                $activeSubmenu.removeClass(activeClassName);
                $activeSubmenu.stop().slideUp();
                $(this).removeClass(buttonOpenClassName);
            } else { // If clicked submenu is not active
                $activeSubmenu.addClass(activeClassName);
                $activeSubmenu.stop().slideDown();
                $(this).addClass(buttonOpenClassName);
            }
        }
    });

    $searchOpen.on('click', function (e) {
        $search.addClass('is-open');
        $search.find('input').focus();
    });

    $searchClose.on('click', function (e) {
        $search.removeClass('is-open');
    });


    var emailPlaceholder = $('.form-email-label').html();
    $basedriverNewsletterForm.find('.wpbd-email').attr('placeholder', emailPlaceholder);

    $toggleFAQ.on('click', function (e) {
        e.stopPropagation();
        var activeClassName = 'is-open';

        if ($(this).hasClass(activeClassName)) {
            $(this).removeClass(activeClassName);
            $(this).next().slideUp();
        } else {
            $toggleFAQ.removeClass(activeClassName);
            $toggleFAQ.next().slideUp();

            $(this).addClass(activeClassName);
            $(this).next().slideDown();
        }
    });
});

function isMobile() {
    return window.matchMedia("only screen and (max-width:" + 992 + "px)").matches;
}
